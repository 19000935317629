import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center'
    },
    startButton: {
        margin: theme.spacing(1),
        width: "200px",
        height: "50px"
    },
}));

export const FinishStage = ({taskId}: { taskId: string }) => {
    const classes = useStyles();
    return (<div>
            <h2>10. To już jest koniec... albo początek...</h2>
            <p>Cześć! Jestem Artur, pracuje jako programista od ponad 2 lat, a programuje chyba całe życie.</p>
            <small>Tworzenie tej strony wykorzystałem do nauki nowej technologii, także prosże nie linczujcie :D</small>
            <p>Chciałem, żeby ta ostatnia strona była moim nietypowym CV (zamiast maila na frizpraca@gmail.com), ale jak zacząłem o sobie pisać, to wyszło, że słaby ze mnie pisarz...</p>
            <p>Jeśli spodobał wam się mój pomysł, zaproście mnie do Krakowa, abym miał szanse osobiście o sobie opowiedzieć.</p>
            <p>Może jedynie w małym skrócie: jestem programistą z ambicjami pilota Boeinga ;) czuje, że u was będę miał co robić (nie tylko programistycznie!)</p>

            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.startButton}
                    endIcon={<LinkedInIcon>send</LinkedInIcon>}
                    onClick={(event) => {
                        window.open("https://www.linkedin.com/in/artur-wegrzyn/", "_blank") //to open new page}
                    }}
                >
                    Linkedin
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.startButton}
                    endIcon={<InstagramIcon>send</InstagramIcon>}
                    onClick={(event) => {
                        window.open("https://www.instagram.com/pizza_traveler/", "_blank") //to open new page}
                    }}
                >
                    Instagram
                </Button>
            </div>
        </div>
    );
}