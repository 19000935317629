import React, {useState} from "react";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import {useVerification} from "../../hooks/useVerification";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column"
    },
    startButton: {
        margin: theme.spacing(1),
        width: "200px",
        height: "50px"
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: green,
    },
});

export const VerificationStage = () => {
    const classes = useStyles();
    const [codeError, setCodeError] = useState<boolean>(false)
    const [code, setCode] = useState<string>("")
    const [initialVerification] = useVerification()
    const history = useHistory()

    const startVerification = (code: string) => {
        initialVerification(code)
            .then(verificationKey => {
                setCodeError(false)
                history.push("/commonStage/" + verificationKey)
            })
            .catch(err => {
                setCodeError(true)
            })
    }

    return (
        <div>
            <h2>1. Weryfikacja</h2>
            <p>Z racji, że link do tej strony jest widoczny publicznie, aby wejść do gry musicie udowodnić, że jesteście
                ekipą i wpisać "kod". Wysłałem
                go do was różnymi kanałami:
                <ol>
                    <li>mail: trombavlog@gmail.com</li>
                    <li>mail: kontakt@ekipatonosi.pl</li>
                    <li>mail: frizpraca@gmail.com</li>
                    <li>instagram: fizoluszek</li>
                    <li>instagram: friz_prawdziwa_historia_ekipy</li>
                    <li>instagram: patec_wariatec</li>
                    <li>instagram: wersow</li>
                    <li>instagram: murcix</li>
                    <li>instagram: nowciax</li>
                    <li>instagram: trombabomba</li>
                    <li>instagram: fusialka</li>
                    <li>instagram: _minimajk</li>
                    <li>instagram: ryskalamarcysia</li>
                    <li>fb: Friz</li>
                    <li>fb: Poczciwy Krzychu</li>
                    <li>... do Wujaszk bałem się pisać</li>
                </ol>

                Szukajcie w swoich skrzynkach ciągów
                tekstowych:
                <ol>
                    <li>JustDev</li>
                    <li>pizza_traveler</li>
                    <li>"Kod do zagadki dla ekipy: "</li>
                </ol>
                i wprowadzcie poniżej znaleziony w ten sposób kod :)
            </p>
            <div className={classes.buttonContainer}>
                <TextField
                    type="password"
                    error={codeError}
                    id="outlined-error-helper-text"
                    label="Wprowadz kod tutaj:"
                    defaultValue=""
                    helperText={codeError ? "Niepoprawny klucz weryfikacyjny !" : ""}
                    variant="outlined"
                    onChange={(event => setCode(event.target.value))}
                    onKeyDown={(event => {
                        if (event.key === 'Enter') {
                            startVerification(code)
                        }
                    })}
                />

                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.startButton}
                        endIcon={<LockOpenOutlinedIcon>send</LockOpenOutlinedIcon>}
                        onClick={(event => startVerification(code))}
                    >
                        Wejdź do gry!
                    </Button>
                </ThemeProvider>
            </div>
            <br/>
            <br/>
            <br/>
            <small>Jeśli mielibyście problem z kluczem to napiszcie na pw:
                https://www.instagram.com/pizza_traveler/ </small>
        </div>
    );
}