import axios from "axios";

const access = "AIzaSyAO_HMix1iRyLDpMfVI0sejgubS7py04Q8"
const friz = "UCwBtP6NDQtsP5YBa4vuZqHA"
const novciax = "UCLRdwTmAnoL-Lr8W9qtiQkA"
const patecWariatec = "UCYsZvmJGPzTMSBXES06-2lg"


export const getFrizStatistics = () => {
    const query = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${friz}&key=${access}`
    return axios.get(query)
}

export const getNowciuStatistics = () => {
    const query = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${novciax}&key=${access}`
    return axios.get(query)
}


export const getPatecStatistics = () => {
    const query = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${patecWariatec}&key=${access}`
    return axios.get(query)
}

export const getYtStatistics = () => {
    return new Promise((resolve, reject) => {
        axios.all([
            getFrizStatistics(),
            getNowciuStatistics(),
            getPatecStatistics()
        ]).then(axios.spread((...responses) => {
            resolve([responses[0].data, responses[1].data, responses[2].data])
        })).catch(errors => {
            console.log(errors)
            reject(errors)
        })
    })
}