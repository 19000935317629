import {doVerification, verifyQuiz} from "../utils/verificationApi";
import {sha1} from "../utils/sha1";
import {useLocalStorageExpire} from "./useLocalStorageExpire";

type HookResponse = [
    (code:string) => Promise<string>
]

export const useVerification = ():HookResponse => {
    const verificationKey = useLocalStorageExpire("verificationKey")

    const initialVerification = (code: string):Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            doVerification(code)
                .then(response => {
                    if (response.status === 200) {
                        verificationKey.setValue(response.data)
                        resolve(response.data)
                    }
                })
                .catch(err => {
                    reject()
                    verificationKey.removeValue()
                })
        })

    }

    return [initialVerification]
}
