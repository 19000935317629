import {useTasks} from "../../hooks/useTasks";
import React, {useEffect, useState} from "react";
import {Task} from "../../types/Task";
import {TextField} from "@material-ui/core";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import {green} from "@material-ui/core/colors";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: "column"
    },
    startButton: {
        margin: theme.spacing(1),
        width: "200px",
        height: "50px"
    },
}));

const greenTheme = createMuiTheme({
    palette: {
        primary: green,
    },
});

export const CommonStage = ({taskId}: { taskId: string }) => {
    const classes = useStyles();
    const [getNextTask, verifyTaskAnswer] = useTasks()
    const [task, setTask] = useState<Task>();

    const [answerError, setAnswerError] = useState<boolean>(false)
    const [answer, setAnswer] = useState("")
    const history = useHistory()


    useEffect(() => {
        getNextTask(taskId)
            .then(task => {
                setTask(task)
            })
            .catch(err => console.log(err))
    }, [taskId])

    const startVerification = (answer: string) => {
        if(answer) {
            verifyTaskAnswer(taskId, answer)
                .then(nextTask => {
                    setAnswerError(false)
                    setAnswer("")
                    history.push("/" + nextTask.stageType + "/" + nextTask.id)
                })
                .catch(err => {
                    setAnswerError(true)
                    console.log(err)
                })
        }
    }

    return (
        <>
            <h2>{task && task.name}</h2>
            <div className={classes.buttonContainer}>
                {task && <div dangerouslySetInnerHTML={{__html: task.content}}></div>}
                {task && <small><div dangerouslySetInnerHTML={{__html: task.tip}}></div></small>}
                <br/>
                <TextField
                    error={answerError}
                    id="outlined-error-helper-text"
                    label="Wprowadz odpowiedz:"
                    defaultValue=""
                    helperText={answerError ? "Niepoprawna odpowiedz !" : ""}
                    variant="outlined"
                    value={answer}
                    onChange={(event => setAnswer(event.target.value))}
                    onKeyDown={(event => {
                        if (event.key === 'Enter') {
                            startVerification(answer)
                        }
                    })}
                />

                <ThemeProvider theme={greenTheme}>
                    <Button
                        id="commonButton"
                        variant="contained"
                        color="primary"
                        className={classes.startButton}
                        endIcon={<LockOpenOutlinedIcon>send</LockOpenOutlinedIcon>}
                        onClick={(event => startVerification(answer))}
                    >
                        Odpowiadam!
                    </Button>
                </ThemeProvider>
            </div>
        </>
    )
}