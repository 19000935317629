import React from "react";
import {BrowserRouter as Router, Route, Switch, RouteComponentProps} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import './App.css';
import {Starter} from "./components/stages/Starter";
import {VerificationStage} from "./components/stages/VerificationStage";
// import {SecondQuiz, SecondQuizQuizKey} from "./components/stages/SecondQuiz";
// import {ThirdQuizQuizKey} from "./components/stages/ThirdQuiz";
import {CommonStage} from "./components/stages/CommonStage";
import {YouTubeStage} from "./components/stages/YouTubeStage";
import {ChooseStage} from "./components/stages/ChooseStage";
import {FinishStage} from "./components/stages/FinishStage";

interface MatchParams {
    taskId: string;
}
interface MatchProps extends RouteComponentProps<MatchParams> {
}

export default function App() {
    return (
        <>
            <CssBaseline/>
            <Container maxWidth="md" style={{
                marginTop: "50px",
                padding: "25px 50px"
            }}>
                <Router>
                    <div>
                        <Switch>
                            <Route path="/verificationStage">
                                <VerificationStage/>
                            </Route>
                            <Route
                                path="/commonStage/:taskId"
                                render={({match}: MatchProps) => (
                                    <CommonStage taskId={match.params.taskId}/>)}
                            />
                            <Route
                                path="/youtubeStage/:taskId"
                                render={({match}: MatchProps) => (
                                    <YouTubeStage taskId={match.params.taskId}/>)}
                            />
                            <Route
                                path="/chooseStage/:taskId"
                                render={({match}: MatchProps) => (
                                    <ChooseStage taskId={match.params.taskId}/>)}
                            />
                            <Route
                                path="/finishStage/:taskId"
                                render={({match}: MatchProps) => (
                                    <FinishStage taskId={match.params.taskId}/>)}
                            />
                            <Route path="/">
                                <Starter/>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </Container>
        </>
    );
}
