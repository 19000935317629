import {useLocalStorageExpire} from "./useLocalStorageExpire";
import {httpClient} from "../utils/httpClient";
import {Task} from "../types/Task";


type HookResponse = [
    (taskId: string) => Promise<Task>,
    (taskId: string, answer: string) => Promise<Task>,

]

export const useTasks = ():HookResponse => {
    // const nextTaskId = useLocalStorageExpire("nextTaskId", 60 * 60 * 24)
    // const currentTask = useLocalStorageExpire("currentTask", 60 * 60 * 24)

    const getNextTask = (taskId: string):Promise<Task> => {
        return new Promise<Task>((resolve, reject) => {
            const query = `https://zagadka.justdev.pl/task/getTask.php?taskId=${taskId}`
            httpClient.get(query)
                .then(response => {
                    // currentTask.setValue(response.data)
                    var currentTask = response.data;
                    resolve(currentTask)
                })
                .catch(reject)
        })
    }

    const verifyTaskAnswer = (taskId: string, answer: string):Promise<Task> => {
        return new Promise<Task>((resolve, reject) => {
            const query = `https://zagadka.justdev.pl/task/verifyTask.php?taskId=${taskId}&answer=${answer}`
            httpClient.get(query)
                .then(response => {
                    const nextTask = response.data;
                    resolve(nextTask)
                })
                .catch(reject)
        })
    }

    return [getNextTask, verifyTaskAnswer]
}




