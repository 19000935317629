export const useLocalStorageExpire = (key: string, timeoutInSeconds: number = 60 * 60 * 24): LocalStorageExpire => {
    return new LocalStorageExpire(key, timeoutInSeconds);
}

class LocalStorageExpire {
    private key: string;
    private timeout: number;

    constructor(key: string, timeout: number) {
        this.key = key;
        this.timeout = timeout;
    }

    exist() {
        const valueWithTimestampString = localStorage.getItem(this.key)
        if (valueWithTimestampString) {
            const valueWithTimestamp: LocalStorageValueWithTimestamp = JSON.parse(valueWithTimestampString)
            if (this.isNotExpired(valueWithTimestamp)) {
                return true;
            } else {
                localStorage.removeItem(this.key)
            }
        }
        return false;
    }

    setValue(value: string) {
        const valueWithTimestamp: LocalStorageValueWithTimestamp = {
            value: value,
            timestamp: LocalStorageExpire.getTimestampInSecond()
        }
        localStorage.setItem(this.key, JSON.stringify(valueWithTimestamp))
    }

    getValue(): string | undefined {
        const valueWithTimestampString = localStorage.getItem(this.key)
        if (valueWithTimestampString) {
            const valueWithTimestamp: LocalStorageValueWithTimestamp = JSON.parse(valueWithTimestampString)
            if (this.isNotExpired(valueWithTimestamp)) {
                return valueWithTimestamp.value
            } else {
                localStorage.removeItem(this.key)
            }
        }
        return undefined;
    }

    removeValue() {
        localStorage.removeItem(this.key)
    }

    increaseExpirationTime(): boolean {
        const valueWithTimestampString = localStorage.getItem(this.key)
        if (valueWithTimestampString) {
            const valueWithTimestamp: LocalStorageValueWithTimestamp = JSON.parse(valueWithTimestampString)
            if (this.isNotExpired(valueWithTimestamp)) {
                this.setValue(valueWithTimestamp.value)
                return true;
            } else {
                localStorage.removeItem(this.key)
            }
        }
        return false;
    }

    setNewTimeout(timeout: number) {
        this.timeout = timeout
    }

    private static getTimestampInSecond() {
        return new Date().getTime() / 1000
    }

    private isNotExpired(valueWithTimestamp: LocalStorageValueWithTimestamp) {
        return valueWithTimestamp.timestamp + this.timeout >= LocalStorageExpire.getTimestampInSecond();
    }
}

interface LocalStorageValueWithTimestamp {
    value: string;
    timestamp: number;
}