import React from "react";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import YouTubeIcon from '@material-ui/icons/YouTube';
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center'
    },
    startButton: {
        margin: theme.spacing(1),
        width: "200px",
        height: "50px"
    },
}));

export const Starter = () => {
    const classes = useStyles();
    return (<div>
            <h2>Cześć ekipo!</h2>
            <p>Zazwyczaj to wy szykujecie <b>teleturnieje</b>, <b>niespodzianki</b> i <b>zagadki</b> (dla siebie i widzów). Tym razem ja jako
                wasz <b>widz</b>, chciałbym rzucić <b>wyzwanie</b> wam!</p>
            <h3>Kilka informacji :)</h3>
            <ol>
                <li>jak pójdzie wam za szybko, to musicie wiedzieć, że miałem dużo pomysłów na "zagadki", ale nie
                    chciałem, żebyście polegli więc postawiłem na prostsze,
                </li>
                <li>jeśli mimo pkt. 1 polegniecie, a "gra" wam sie spodobała, to nie krępujcie się, znacie do mnie namiary,
                </li>
                <li>pierwsze zadanie będzie weryfikacją, czy to faktycznie <b>EKIPA</b> próbuje zdobyć dostęp do gry ;)
                </li>
            </ol>
            <div className={classes.buttonContainer}>
                <Link to="/verificationStage">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.startButton}
                        endIcon={<YouTubeIcon>send</YouTubeIcon>}

                    >
                        Start
                    </Button>
                </Link>
            </div>
        </div>
    );
}